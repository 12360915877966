import useStyle from '@hooks/useThemeHelper';
import { usePageStyles } from '@components/styles';
import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import { incidentPageDCID } from '@dataCventIds';
import FormElement from '@cvent/carina/components/FormElement/FormElement';
import {
  getLastUpdatedDateMinutes,
  getSanitizedIncidentNotesHtml,
  IncidentLabel
} from '@components/page/incident/common';
import React from 'react';
import { Form } from '@cvent/carina/components/Forms/Form';
import { CommonContentContainer } from '@components/CommonContentContainer';
import { PastNote } from '@typings/graph';
import { POSTGRES_DEFAULT_INCIDENT_END_DATE } from '@constants';

const dateRegex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-9]|[01][0-9]):([0-5][0-9])Z/;
const dateReplaceRegex = /<[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):([0-5][0-9])Z>/;

function getUpdateTime(date: string, incidentStartDate: string, incidentEndDate: string): string {
  const timeZoneValue = new Intl.DateTimeFormat().resolvedOptions();
  if (
    ((!incidentEndDate || incidentEndDate === POSTGRES_DEFAULT_INCIDENT_END_DATE) &&
      new Date().getDate() !== new Date(incidentStartDate).getDate()) ||
    (incidentEndDate && new Date(incidentEndDate).getTime() - new Date(incidentStartDate).getTime() > 24)
  ) {
    const dateLocal = new Date(date).toLocaleDateString(timeZoneValue.locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    });
    return dateLocal;
  }

  const dateLocal = new Date(date).toLocaleString(timeZoneValue.locale, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  });
  return dateLocal;
}

interface ICustomerImpactProps {
  customerImpact: string;
}

function CustomerImpact({ customerImpact }: ICustomerImpactProps): JSX.Element {
  const { incidentNotes, incidentNotesSubHeaders } = useStyle(usePageStyles);
  return (
    <Row>
      <div css={{ ...incidentNotesSubHeaders }}>Customer Impact</div>
      <span
        css={incidentNotes}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: getSanitizedIncidentNotesHtml(customerImpact)
        }}
      />
    </Row>
  );
}

interface IIncidentLatestUpdateProps {
  incidentCurrentUpdates: string;
  lastModifiedDate: string;
}

function IncidentLatestUpdate({ incidentCurrentUpdates, lastModifiedDate }: IIncidentLatestUpdateProps): JSX.Element {
  const { incidentNotes, incidentNotesSubHeaders, incidentValueLabels } = useStyle(usePageStyles);
  if (incidentCurrentUpdates) {
    return (
      <Row margin={{ top: 12 }}>
        <Col padding={{ start: 0 }} width={2.5 / 4}>
          <div css={{ ...incidentNotesSubHeaders }}>Latest Update</div>
        </Col>

        <Col
          l={{ width: 1.5 / 4 }}
          flex={{ display: 'flex', justifyContent: 'right' }}
          id={incidentPageDCID.lastModifiedDateValue}
        >
          <FormElement.Label
            labelFor={lastModifiedDate}
            label={getLastUpdatedDateMinutes(lastModifiedDate, incidentValueLabels)}
            testID={incidentPageDCID.lastModifiedDateValue}
          />
        </Col>
        <span
          css={{ ...incidentNotes }}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: getSanitizedIncidentNotesHtml(incidentCurrentUpdates)
          }}
        />
      </Row>
    );
  }
  return null;
}
function getSortedPastUpdateNotesForDisplay(
  pastUpdates: string,
  incidentStartDate: string,
  incidentEndDate: string
): PastNote[] {
  const pastNotes = pastUpdates?.split(/<br\s*\/?>/).filter(note => note.trim() !== '') || [];
  const pastNotesSorted = [];
  // eslint-disable-next-line array-callback-return
  pastNotes.map(note => {
    const matchedDate = note.match(dateRegex);
    const localDate = matchedDate ? getUpdateTime(matchedDate[0], incidentStartDate, incidentEndDate) : '';
    const pastNote = {
      note: getSanitizedIncidentNotesHtml(note.replace(dateReplaceRegex, '')),
      updateDate: localDate
    };
    pastNotesSorted.push(pastNote);
  });
  return pastNotesSorted.sort((a, b) => {
    const timeA = new Date(a.updateDate);
    const timeB = new Date(b.updateDate);
    if (timeA > timeB) {
      return -1;
    }
    if (timeA < timeB) {
      return 1;
    }
    return 0;
  });
}

interface IIncidentHistoryProps {
  incidentPastUpdates: string;
  incidentStartDate: string;
  incidentEndDate: string;
}

function IncidentHistory({
  incidentPastUpdates,
  incidentStartDate,
  incidentEndDate
}: IIncidentHistoryProps): JSX.Element {
  const { incidentNotes, incidentNotesSubHeaders, incidentNotesUl, incidentNotesHistoryDate } = useStyle(usePageStyles);
  const incidentPastNotes =
    getSortedPastUpdateNotesForDisplay(incidentPastUpdates, incidentStartDate, incidentEndDate) || [];
  if (incidentPastNotes.length > 0 && incidentPastNotes[0].note !== '') {
    return (
      <Row margin={{ top: 10, end: 0 }}>
        <Row style={incidentNotesSubHeaders}>Incident History</Row>
        <Row margin={{ end: -5 }} css={{ paddingRight: '0px' }}>
          <Col padding={{ end: 0 }} flex={{ display: 'flex' }}>
            <ul css={{ ...incidentNotesUl }}>
              {incidentPastNotes.map(note => (
                <li css={{ paddingTop: '5px', paddingRight: '0px' }}>
                  <Row margin={{ start: 0, end: 0 }}>
                    <Col width={3 / 4} padding={{ start: 0 }}>
                      <span
                        css={incidentNotes}
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                          __html: note.note
                        }}
                      />
                    </Col>
                    <Col width={1 / 4} flex={{ justifyContent: 'right', display: 'flex' }} padding={{ end: 0 }}>
                      <IncidentLabel styling={incidentNotesHistoryDate} text={note.updateDate} />
                    </Col>
                  </Row>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Row>
    );
  }
  return null;
}

export interface IIncidentNotesProps {
  customerImpact: string;
  currentUpdate: string;
  incidentHistory: string;
  lastModifiedDate: string;
  incidentStartDate: string;
  incidentEndDate: string;
}

export function IncidentNotes({
  customerImpact,
  currentUpdate,
  lastModifiedDate,
  incidentHistory,
  incidentStartDate,
  incidentEndDate
}: IIncidentNotesProps): JSX.Element {
  const { parentTileStyle } = useStyle(usePageStyles);
  return (
    <CommonContentContainer width={parentTileStyle.width} styling={{ ...parentTileStyle }}>
      <Form.Section heading="Incident Notes" stack>
        <CustomerImpact customerImpact={customerImpact} />
        <IncidentLatestUpdate incidentCurrentUpdates={currentUpdate} lastModifiedDate={lastModifiedDate} />
        <IncidentHistory
          incidentPastUpdates={incidentHistory}
          incidentEndDate={incidentEndDate}
          incidentStartDate={incidentStartDate}
        />
      </Form.Section>
    </CommonContentContainer>
  );
}
